/* Chrome, Safari, Edge, Opera */
.input-remove-hyphen::-webkit-outer-spin-button,
.input-remove-hyphen::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input-remove-hyphen[type='number'] {
    -moz-appearance: textfield;
}
