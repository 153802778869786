.dotted {
    border: 1px dotted rgba(0, 0, 0, 0.5) !important;
    border-style: none none dotted !important;
    cursor: pointer !important;
}

.divider {
    display: flex;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
}
.line {
    align-items: center;
    margin: 1em -1em;
}

.line::before,
.line::after {
    height: 1px;
    margin: 0 1em;
}

.razor::before,
.razor::after {
    box-shadow: 0 0.5px 0 black;
    /* border-bottom: 0.5px solid black;  */
}
