.availability-switch .euiButtonGroup__buttons {
    border-radius: 45px !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    cursor: auto;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    outline: none !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(1):hover {
    background-color: #bd271e !important;
    opacity: 0.2 !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2):hover {
    background-color: transparent !important;
    outline: none !important;
    outline-color: transparent;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2):focus {
    outline: none !important;
    outline-color: transparent;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2):visited {
    outline: none !important;
    outline-color: transparent;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2):focus-visible {
    outline: none !important;
    outline-color: transparent;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(3):hover {
    background-color: #00bfb3 !important;
    opacity: 0.2 !important;
}

.availability-switch .euiButtonGroup--compressed .euiButtonGroupButton--warning:not([class*='isDisabled']):focus,
.euiButtonGroup--compressed .euiButtonGroupButton--warning:not([class*='isDisabled']):focus-within {
    outline: none !important;
    outline-color: transparent;
}

.availability-switch
    .euiButtonGroup--compressed
    .euiButtonGroupButton--warning:not([class*='isDisabled']):focus-within {
    outline: none !important;
    outline-color: transparent;
}

/* availability-switch */

.availability-switch .euiButtonGroupButton--small {
    border-radius: 50% !important;
    /* height: ; */
    height: 26px !important;
    width: 26px !important;
    align-self: center;
}

/* ITEM SWITCH */

.item-availability-switch .euiButtonGroup__buttons {
    border-radius: 26px !important;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    cursor: auto;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    outline: none !important;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(1):hover {
    background-color: #bd271e !important;
    opacity: 0.2 !important;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2):hover {
    background-color: transparent !important;
    outline: none !important;
    outline-color: transparent;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2):focus {
    outline: none !important;
    outline-color: transparent;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2):visited {
    outline: none !important;
    outline-color: transparent;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(2):focus-visible {
    outline: none !important;
    outline-color: transparent;
}

.item-availability-switch .euiButtonGroup__buttons label:nth-child(3):hover {
    background-color: #00bfb3 !important;
    opacity: 0.2 !important;
}

.item-availability-switch .euiButtonGroupButton--small {
    border-radius: 50% !important;
    /* height: ; */
    height: 20px !important;
    width: 20px !important;
    align-self: center;
}

/* SUB CATEGORY SIWTCH SWITCH */

.sub-category-availability-switch .euiButtonGroup__buttons {
    border-radius: 26px !important;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    cursor: auto;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    outline: none !important;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(1):hover {
    background-color: #bd271e !important;
    opacity: 0.2 !important;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2):hover {
    background-color: transparent !important;
    outline: none !important;
    outline-color: transparent;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2):focus {
    outline: none !important;
    outline-color: transparent;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2):visited {
    outline: none !important;
    outline-color: transparent;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(2):focus-visible {
    outline: none !important;
    outline-color: transparent;
}

.sub-category-availability-switch .euiButtonGroup__buttons label:nth-child(3):hover {
    background-color: #00bfb3 !important;
    opacity: 0.2 !important;
}

.sub-category-availability-switch .euiButtonGroupButton--small {
    border-radius: 50% !important;
    /* height: ; */
    height: 23px !important;
    width: 23px !important;
    align-self: center;
}
