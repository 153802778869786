.menuHeightClass {
    /* width: 80% !important; */
    height: calc(100vh - 149px) !important;
    overflow: auto !important;
}

.display_style {
    flex-direction: row;
}

.category_item_style {
    min-width: 25%;
    flex: 0 1 25%;
    width: 25%;
}

.item_details {
    min-width: 45%;
    flex: 0 1 45%;
    width: 45%;
}

.menuStyle {
    height: 72vh !important;
    overflow: auto;
}

.itemDetailStyle {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    max-height: 72vh !important;
}

@media screen and (max-width: 1300px) {
    .menuStyle {
        height: 58vh !important;
        overflow: auto;
    }

    .itemDetailStyle {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        height: 58vh !important;
    }
}

@media screen and (max-width: 800px) {
    .display_style {
        flex-direction: column;
    }

    .category_item_style {
        flex: 0 1 90%;
        width: 90%;
    }

    .item_details {
        min-width: 90%;
        flex: 0 1 90%;
        width: 90%;
    }
}
