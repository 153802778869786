.Timmer {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
}

.time {
    font-size: 32px;
}
