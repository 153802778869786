/* STYLES FOR SCROLLBAR */

/* total width */
.scrollbar::-webkit-scrollbar {
    width: 8px;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
    background-color: #98989d;
    border-radius: 100px;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
    display: none;
}

.active-border-card {
    border-radius: 6px;
    outline: 4px solid #2b77cc;
}

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}
.img-preview {
    overflow: hidden;
}

.scrollbar {
    overflow-y: scroll;
}

.gradient-border {
    border: double 3px transparent;
    background-image: linear-gradient(white, white), linear-gradient(to right, green, gold);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 6px;
    box-sizing: border-box;
}

.euiComboBoxPlaceholder {
    font-family: 'Inter UI';
}

/* SCROLLBAR SIZE VARIANT */

.scrollbar-sm::-webkit-scrollbar {
    width: 6px;
    /* background-color: indianred; */
}

.scrollbar-sm::-webkit-scrollbar-thumb {
    background-color: #98989d;
    border-radius: 100px;
}

.scrollbar-sm::-webkit-scrollbar-button {
    display: none;
}

.scrollbar-sm {
    overflow-y: scroll;
}

.scrollbar,
.scrollbar-sm {
    overflow-x: hidden;
}

/* REMOVE SCROLLBAR */
.remove-scrollbar {
    scrollbar-width: none;
}
.remove-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
