.react-time-picker {
    width: 100%;
}
.react-time-picker__wrapper {
    padding: 5px;
    border-radius: 5px;
}
.react-time-picker__inputGroup__input:invalid {
    background: rgb(255 255 255);
}
.react-time-picker__inputGroup__input {
    min-width: 18px !important;
}

.CalendarDay__selected_span {
    background: #275af6;
    color: white;
    border: 1px solid #79aad9;
}

.CalendarDay__selected {
    background: #79aad9;
    color: white;
}

.CalendarDay__selected:hover {
    background: rgba(213, 66, 50, 0.612);
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: brown;
}
.DateRangePicker_picker {
    z-index: 1000 !important;
}
