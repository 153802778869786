//Show the borders properly in EUI
.euiHeaderSectionItem {
    &:after {
        top: -4px !important;
    }
}

.euiHeaderSection--left {
    margin-right: 3%;
}
