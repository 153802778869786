.login-logo-page figure {
    display: block !important;
    margin: 0 auto !important;
}

.login-page-container {
    min-width: 400px;
}

.login-panel {
    max-width: 430px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    border: none !important;
    min-height: 380px;
}

.login-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    margin-top: 30px !important;
}

@media (max-width: 400px) {
    .login-page-container {
        min-width: 280px;
    }
}
