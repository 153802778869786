/* .euiModal {
    min-width: 450px;
}
.euiModal .euiModal__flex {
    min-height: 58vh;
} */

.inlineWidth {
    width: 30%;
    margin: 0 10px;
}

@media only screen and (max-width: 1400px) {
    .restaurant-image {
        flex-wrap: wrap;
    }
}
