.bogoItemStyle {
    border-left: 1px solid blue;
    padding-left: 12px;
}

.orderPanel {
    height: calc(100vh - 105px) !important;
    overflow: auto !important;
}

.pastOrderPanel {
    height: calc(100vh - 175px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.abandonedOrderPanel {
    height: calc(100vh - 212px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.customerOrderPanel {
    height: calc(100vh - 197px) !important;
    overflow: auto !important;
}

.rowFlex {
    display: flex !important;
    flex-direction: row !important;
}

.orderFirstTitle {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
}

.orderSecondTitle {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.5;
}

.orderAddressDetails {
    font-size: 14px;
    font-weight: 500;
    line-height: 1rem;
    color: #69707d;
}

.orderDetailTime {
    font-size: 14px;
    color: #69707d;
    /* extra */
    line-height: 1.7rem;
}

.orderDetailOutletName {
    font-size: 14px;
    font-weight: 500;
    color: #abb4c4;
    /* extra */
    line-height: 1rem;
}

.orderDetailTableHeader {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
}

.orderDetailCategory {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #f5f7fa;
    padding: 9px;
}
.categoryTableHeight {
    height: 24px !important;
}
.customRowClassForCategory {
    height: 24px !important;
    /* background-color: #f5f7fa !important; */
    background-color: var(--var-for-theme) !important;
    font-size: 12px !important;
    font-weight: bold !important;
    /* border-bottom: 1px solid black; */
}

.customCellClassForTopBorder {
    border-top: none;
}

.customCellClassForBottomBorder {
    border-bottom: none;
}

.customCellClassForVariant {
    border-bottom: none !important;
    border-top: none !important;
}

.radioFontSize {
    font-size: '14px';
}

.euiTableRow-isExpandedRow .euiTableCellContent {
    padding: 0px 12px !important;
    /* background-color: rgb(252, 253, 254) !important; */
    background-color: var(--var-for-theme) !important;
    animation: none !important;
}

.borderColorForTable {
    text-align: right !important;
    border-bottom: 1px solid rgba(211, 218, 230, 0.5) !important;
    border-top: 1px solid rgba(211, 218, 230, 0.5) !important;
}

.borderForFirstTd {
    text-align: left;
    font-size: 12px;
    width: 70%;
    height: 20px;
    padding: 3px 5px;
    border-right: 1px solid rgba(211, 218, 230, 0.5);
}

.borderForSecondTd {
    font-size: 12px !important;
    width: 30% !important;
    height: 20px !important;
    padding: 3px 5px !important;
}

.iconMarginRight {
    margin-right: 5px !important;
}
